import React from 'react';

import HelpLayout from '../../../components/HelpLayout';
import HelpArticle from '../../../components/HelpArticle';
import SEO from '../../../components/SEO';

const HowToUploadVideoPage = ({ location }) => {
  return (
    <HelpLayout location={location}>
      <SEO
        title="How to upload video"
        description="Learn how to upload a video."
      />
      <HelpArticle title="How to upload video">
        <HelpArticle.StepContent>
          <p>
            Select <strong>Upload video</strong> in the menu on the left.
          </p>
        </HelpArticle.StepContent>
        <HelpArticle.StepContent>
          <p>
            Select the videos you want to upload in your system's file manager,
            drag and drop them onto the white rectangle in the center of the
            website. Alternatively, you can click on the{' '}
            <strong>browse files</strong> text, which opens the dialog where you
            select the video files you want to upload.
          </p>
        </HelpArticle.StepContent>
        <HelpArticle.StepContent>
          <p>
            Click on the green button <strong>Upload files</strong> in the
            bottom left corner.
          </p>
        </HelpArticle.StepContent>
        <HelpArticle.StepContent>
          <p>
            That's it! Now you just wait for the video files to be uploaded.
          </p>
        </HelpArticle.StepContent>
      </HelpArticle>
    </HelpLayout>
  );
};

export default HowToUploadVideoPage;
